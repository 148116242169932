import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './core';

export const AppRoutes: Routes = [
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      // {
      //   path: '',
      //   redirectTo: '/checkout/kits',
      //   pathMatch: 'full'
      // },
      // {
      //   path: 'checkout',
      //   loadChildren: './checkout/checkout.module#CheckoutModule'
      // },
      {
        path: '',
        redirectTo: 'buscador/buscadortarjeta',
        pathMatch: 'full'
      },
      {
        path: 'buscador',
        loadChildren: './buscador/buscador.module#BuscadorModule'
      },
      {
        path: '',
        redirectTo: 'ubicanos',
        pathMatch: 'full'
      },
      {
        path: 'ubicanos',
        loadChildren: './ubicanos/ubicanos.module#UbicanosModule'
      },
      {
        path: 'buscadorgt',
        loadChildren: './guatemala/buscadorgt.module#BuscadorGTModule'
      }
    ]

    /*path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './checkout/checkout.module#CheckoutModule'
      },
      {
        path: 'checkout',
        loadChildren: './checkout/checkout.module#CheckoutModule'
      }
    ]*/


  },
  {
    path: '**',
    redirectTo: 'error/404'
  }
];
